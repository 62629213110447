<template>
  <list-container-widget
    :id="type ? `type-${type.key}` : 'societies-list'"
    :app-id="123"
    :title="translatedType"
    :type="type ? type.key : null"
    :size="total"
    :stretch="false"
    hidden-actions
    :disableViewMore="true"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="societies" />
    </template>
    <organizations-paginated-table
      v-if="canList"
      :type-key="type ? type.key : null"
      :listing-type="listingType"
      :load-first-page="loadFirstPage"
      :per-page="8"
      :update-search="updateSearch "
      :enable-card-size="enableCardSize"
      class="organizations-table"
      :is-societies="true"
      @total="getTotal"
    />
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="organizationsPlaceholder" />
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t("no-permisions.message") }}
        </p>
      </b-col>
    </b-row>
  </list-container-widget>
</template>
<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
// import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
// import OrganizationEditModal from '@/views/apps/organization/components/OrganizationEditModal.vue';
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import CardWidgetMixin from "@core/mixins/widgets/CardWidgetMixin";
import OrganizationsPlaceholder from '@/assets/images/placeholders/light/organizations.svg';
import OrganizationsPaginatedTable from "@/views/apps/organization/components/OrganizationsPaginatedTable.vue";

export default {
  name: "OrganizationsTableWidget",
  components: {
    ListContainerWidget,
    OrganizationsPaginatedTable,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),

  },
  mixins: [WidgetLayoutMixin, CardWidgetMixin],
  props: {
    type: {
      type: Object,
      default: null,
    },
    listingType: {
      type: Number,
      required: true,
    },
    loadFirstPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 1,
      isLoading: false,
      index: 0,
      results: {},
      currentPage: 1,
      isCreateModalOpended: false,
      modalTitle: '',
      search: '',
      updateSearch: null,
      total: 0,
    };
  },
  computed: {
    organizationsPlaceholder() {
      return OrganizationsPlaceholder;
    },
    canList() {
      return (
        checkPermissions(
          "view",
          'society',
          this.loggedMemberRoles,
          this.collective
        )
      );
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    translatedType() {
      if (!this.type && this.app) {
        return this.widgetName;
      }

      if (!this.type || this.type.key === "null") {
        return this.$t("organizations.title");
      }

      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.type.name
      );
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
  },
  methods: {
    getTotal(value) {
      this.total = value;
    },

    handleChanger(search, page = { page: 1 }) {
      this.searchPage = page.page;
      this.search = search;
      this.updateSearch = {searchPage: this.searchPage, search: this.search };
    },
    handleCreatedOrganization() {
      this.updateSearch = {searchPage: 1, search: ''};
    }
  },
};
</script>

  <style lang="scss" scoped>
  .members-table {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    flex: 1;
    padding-bottom: 20px;
  }
  </style>